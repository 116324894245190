import React from 'react';
import Layout from '../components/Layout';
import data from "../../content/resources/block.json";
import {Link} from "gatsby";

const Service_one = () => {
    return (
        <Layout>
            <div id="main">
                <div className="services_section_banner">
                    <div
                        className="services_banner"
                        style={{
                            backgroundImage: `url(https://images.pexels.com/photos/844124/pexels-photo-844124.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260)`,
                        }}
                    >
                        <h1>Blockchain Development Company</h1>
                    </div>
                </div>
                <div className="inner">
                    <section id="services">
                        <div className="services_section_1">
                            <h1 className="services_section_1__title" style={{fontSize: "x-large"}}>Hire The Expert
                                Development Team of Blockchain
                                Technology!</h1>
                        </div>
                        <section className="tiles">
                            {data.map((service) => {
                                return (
                                    <article key={service.id} className="style1">
                                    <span className="image">
                                        <img
                                            src={service.image}
                                            alt={service.alt}/>
                                    </span>
                                        <Link to={service.path} state={{service: service}}>
                                            <h2>{service.name}</h2>
                                            <div className="content">
                                                <p>
                                                    {service.description}
                                                </p>
                                            </div>
                                        </Link>
                                    </article>
                                )
                            })}
                        </section>
                    </section>

                </div>
            </div>
        </Layout>
    );
};

export default Service_one;
